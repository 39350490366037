import {DefaultComponentAnalyticsContext} from '../../constants/loginDefault';

const EXP_COMPACT_LAYOUT = 'exp_shop_login_new_ui:variant_modal_compact';

const ANALYTICS_CONTEXTS_TO_COMPACT = [
  DefaultComponentAnalyticsContext.Default,
  DefaultComponentAnalyticsContext.CheckoutModal,
  DefaultComponentAnalyticsContext.ClassicCustomerAccounts,
  DefaultComponentAnalyticsContext.Web,
  DefaultComponentAnalyticsContext.SelfServe,
];

/**
 * Identify if given experiment is compact layout
 * @param {string|undefined} experimentsAndVariants the experiments and variants string
 * @param {string|undefined} analyticsContext the analytics context string
 * @returns {boolean} whether the experiment is compact layout
 */
export function isCompactLayout(
  experimentsAndVariants?: string,
  analyticsContext?: string,
): boolean {
  const experimentsAndVariantsArray = experimentsAndVariants?.split(';') ?? [];
  const isCompactExperiment =
    experimentsAndVariantsArray.includes(EXP_COMPACT_LAYOUT);
  const applyCompactToAnalyticsContext = analyticsContext
    ? ANALYTICS_CONTEXTS_TO_COMPACT.includes(
        analyticsContext as DefaultComponentAnalyticsContext,
      )
    : false;

  return isCompactExperiment && applyCompactToAnalyticsContext;
}
